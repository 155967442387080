.year {
  font-size: 2rem;
  font-weight: 300;
  text-align: right;
  padding-right: 2rem;
  @media only screen and (max-width: 600px) {
    font-size: 2rem;
    font-weight: 300;
    text-align: right;
    padding-right: 0.5rem;
  }
}

.shareholder-logo {
  max-height: 3.5rem;
  max-width: 10rem;
  padding-left: 2rem;
  @media only screen and (max-width: 600px) {
    margin-top: 2rem;
    max-height: 3rem;
    max-width: 5rem;
    padding-left: 0.5rem;
  }
}

.shareholders-name {
  font-size: 1rem;
  font-weight: 500;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 0.5rem;
  }
}

.shareholders-link {
  text-decoration: none;
  @media only screen and (max-width: 600px) {
    margin-left: 0.5rem;
  }
}

.shareholders-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
