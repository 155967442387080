.navbar {
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  height: 80px;
  z-index: 1000;
  @media only screen and (max-width: 600px) {
    height: 60px;
  }
}

.navbar-shadow {
  box-shadow: 0px 1px 10px #999999;
}

.navbar-logo {
  height: 70px;
  width: auto;
  @media only screen and (max-width: 600px) {
    height: 50px;
    width: auto;
  }
}

.navbar-hrmenulist {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-hrmenulist li {
  float: left;
  margin-left: 2rem;
  color: $title-color;
}

.navbar-hrmenulist li a {
  color: $title-color;
  text-decoration: none;
}

.navbar-hrmenulist li a:hover {
  color: $enerdis-blue;
  text-decoration: underline;
  cursor: pointer;
}

.navbar-hrmenulist li.active {
  float: left;
  margin-left: 2rem;
  color: $enerdis-blue;
}

.navbar-drawermenulist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 250px;
}
.navbar-drawermenulist li {
  margin-left: 1rem;
  margin-top: 1rem;
  color: $title-color;
}

.navbar-drawermenulist li.active {
  color: $enerdis-blue;
}

.navbar-drawermenulist li a,
.navbar-drawermenulist li a:active {
  color: $title-color;
  text-decoration: none;
}

.navbar-drawermenulist li a:hover {
  color: $enerdis-blue;
  text-decoration: underline;
  cursor: pointer;
}
