.about-block-wrapper {
  width: 100%;
  height: 420px;
  // background-color: $blockSeparator-bg;
  background-image: url("../../resources/images/about_bg_web.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 3rem;
  padding-bottom: 1rem;

  @media only screen and (max-width: 600px) {
    height: 200px;
    height: 680px;
  }
}

.about-block-overlay-wrapper {
  padding: 2rem;
  background-color: rgba(53, 53, 53, 0.7);
  width: 60%;
  @media only screen and (max-width: 1124px) {
    width: 80%;
  }

  @media only screen and (max-width: 816px) {
    width: 100%;
  }
}

.about-title {
  font-weight: 200;
  font-size: 1.4rem;
  color: white;
}

.about-content {
  font-weight: 200;
  font-size: 1rem;
  color: white;
  text-align: justify;
  margin-top: 1rem;
}
