.footer {
  width: 100%;
  background-color: $enerdis-blue;
  padding: 1rem;
  padding-top: 2rem;
  .copy {
    color: $light-gray;
    text-align: center;
    font-size: 0.8125rem;
    font-weight: 200;
  }
}
