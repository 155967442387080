.blockSeparator-wrapper {
  padding-top: 4rem;
  padding-bottom: 3rem;
  @media only screen and (max-width: 600px) {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}

.blockBackground-bg {
  background-color: $blockSeparator-bg;
}

.blockSeparator-line {
  width: 100px;
  height: 5px;
  background-color: $enerdis-blue;
}
