.contact-block-wrapper {
  padding-bottom: 3rem;
}

.contact-textfield-wrapper {
  padding-bottom: 1rem;
}

.contact-field {
  width: 80%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.contact-button-wrapper {
  padding-top: 2rem;
}

.contact-companyname {
  font-weight: 500;
  font-size: 1.2rem;
  color: $content-color;
  @media only screen and (max-width: 600px) {
    margin-top: 3rem;
  }
}

.contact-address {
  padding-top: 1rem;
  font-weight: 200;
  font-size: 1rem;
  color: $content-color;
}

.contact-phone {
  padding-top: 1rem;
  font-weight: 200;
  font-size: 1rem;
  color: $content-color;
}

.contact-who {
  padding-top: 1rem;
  font-weight: 200;
  font-style: italic;
  font-size: 0.9rem;
  color: $subtitle-color;
  @media only screen and (max-width: 600px) {
    font-size: 1rem;
  }
}

.contact-name {
  font-weight: 500;
  font-size: 1rem;
  color: $content-color;
}
.contact-function {
  font-weight: 500;
  font-size: 0.9rem;
  color: $content-color;
}

.contact-email a {
  color: $enerdis-blue;
  text-decoration: none;
}
.contact-email a:hover {
  cursor: pointer;
  color: $enerdis-blue;
  text-decoration: underline;
}
