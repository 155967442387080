* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: poppins, sans-serif;
  font-weight: 200;
  font-style: normal;
}

// body {
//   color: $dark-grey;
//   background-color: $body-bg;
// }
