.service-box-wrapper {
  margin: 2rem;
  padding: 0rem;
  border: 1px solid red;
  border-color: $services-box-border-color;
  border-width: 1px;
  border-radius: 2px;
  height: 15rem;
}

.service-box-content-wrapper {
  margin-top: 1rem;
  padding: 1rem;
}

.service-screen-title {
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 2rem;
}

.service-screen-content {
  font-weight: 200;
  text-align: center;
  color: $content-color;
  line-height: 1.6rem;
}

.service-icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: rgb(228, 228, 228);
  margin-left: auto;
  margin-right: auto;
  transform: rotateY(0deg) rotate(45deg);
  margin-top: -20px;
  border-width: 5px;
  border-color: white;
  border-style: solid;
}

.service-icon-wrapper > svg {
  // width: 26px;
  // height: 26px;
  transform: rotateY(0deg) rotate(-45deg);
  opacity: 0.3;
  margin-top: 5px;
  margin-left: 5px;
}
