.kpis-block-wrapper {
  background-color: $blockSeparator-bg;
  padding-bottom: 2rem;
}

.kpis-value {
  color: $enerdis-blue;
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
}

.kpis-label {
  color: $enerdis-blue;
  font-size: 1.125rem;
  text-align: center;
  @media only screen and (max-width: 600px) {
    margin-bottom: 2rem;
  }
}
