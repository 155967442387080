.enerdis-gradient-separator {
  height: 8px;
  width: 100%;
  background: linear-gradient(90deg, $enerdis-orange 0%, $enerdis-green 50%, $enerdis-blue 100%);
}

.first {
  margin-top: 80px;
  @media only screen and (max-width: 600px) {
    margin-top: 60px;
  }
}
