.text {
  font-size: 1.2rem;
  line-height: 28px;
  // margin-top: 12px;
  font-weight: 300;
}

.title {
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 300;
  font-style: normal;
  color: $title-color;
  padding-top: 2rem;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding-top: 1rem;
  }
}

.subtitle {
  font-size: 1rem;
  line-height: 1;
  font-weight: 200;
  font-style: normal;
  color: $subtitle-color;
  padding-top: 1rem;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding-top: 0.6rem;
  }
}
