// colors
$enerdis-orange: rgba(255, 206, 0, 1);
$enerdis-green: rgba(17, 170, 0, 1);
$enerdis-blue: rgba(0, 91, 151, 1);

$content-color: rgb(70, 70, 70);

$title-color: rgba(53, 53, 53, 1);
$subtitle-color: rgba(117, 117, 117, 1);

$light-gray: #fafafa;

$blockSeparator-bg: rgba(239, 239, 239, 1);

$services-box-border-color: rgba(53, 53, 53, 0.2);
// navbar

// $dark-grey: #333333;
// $light-grey: #666666;
// $navbar-link: #ffffff;
// $topmenu-link: #333333;
// $topmenu-link-active: #2196f3;

// Poppins Italic

// font-family: poppins, sans-serif;

// font-weight: 400;

// font-style: italic;

// Poppins Regular

// font-family: poppins, sans-serif;

// font-weight: 400;

// font-style: normal;

// Poppins Bold Italic

// font-family: poppins, sans-serif;

// font-weight: 700;

// font-style: italic;

// Poppins Bold

// font-family: poppins, sans-serif;

// font-weight: 700;

// font-style: normal;

// font-family: poppins, sans-serif;
// font-weight: 100;
// font-style: normal;
